import React from 'react';
import VenueMainBlock from 'components/block-elements/venue-main-block/venue-main-block';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';

const StadiumPageBlock = (props) => {

    // const searchParams = new URLSearchParams(props.location.search);
    // const searchText = searchParams.get('searchText')
    // const page = searchParams.get('page')

    return (
    <>
    <Helmet
    title='Stadiums'>

    </Helmet>
        <PageLayout>
            <VenueMainBlock />
        </PageLayout>
    </>
    );
};

export default StadiumPageBlock;
